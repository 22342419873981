<template>
    <div class="web-app-inbox">
        <div class="mobile-notifications-filters d-block d-md-none">
            <button
                type="button"
                class="change-mobile-notifications-filter"
                @click="toggleNotificationsFiltersDropdown"
            >
                {{ selectedFilterLabel }}
                <img src="@assets/img/icons/chevron-down.svg">
            </button>
            <transition name="max-height">
                <div v-if="showNotificationsFiltersDropdown" class="mobile-notifications-filters-dropdown">
                    <notifications-filters :notifications-labels="notificationsLabels" @selected-filter="selectedFilter" />
                </div>
            </transition>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <notifications-list :notifications-type="notificationsType" />
                </div>
                <div class="col-3 d-none d-md-block">
                    <notifications-filters />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WebAppInbox",
    components: {
        NotificationsList: () => import(/* webpackChunkName: "notifications-list" */ "./notifications-list/"),
        NotificationsFilters: () => import(/* webpackChunkName: "notifications-filters" */ "./notifications-filters")
    },
    data() {
        return {
            selectedFilterLabel: "All Notifications",
            showNotificationsFiltersDropdown: false,
            notificationsTypesList: {
                "web-app-inbox-all-notifications": "0",
                "web-app-inbox-followers-notifications": "7",
                "web-app-inbox-comments-notifications": "8",
                "web-app-inbox-likes-notifications": "14",
                "web-app-inbox-mentions-notifications": "19|20"
            },
            notificationsLabels: {
                "web-app-inbox-all-notifications" : "All Notifications",
                "web-app-inbox-likes-notifications" : "Likes Notifications",
                "web-app-inbox-comments-notifications" :"Comments Notifications",
                "web-app-inbox-followers-notifications" : "Followers Notifications",
                "web-app-inbox-mentions-notifications" : "Mentions Notifications"
            }
        }
    },
    computed: {
        notificationsType() {
            return this.notificationsTypesList[this.$route.name];
        }
    },
    created() {
        this.selectedFilterLabel = this.notificationsLabels[this.$route.name];
    },
    methods: {
        toggleNotificationsFiltersDropdown() {
            this.showNotificationsFiltersDropdown = !this.showNotificationsFiltersDropdown;
        },
        selectedFilter(value) {
            this.selectedFilterLabel = value;
            this.toggleNotificationsFiltersDropdown();
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-inbox {
    padding-top: 100px;
    background-color: #0F0F10;
    height: 100vh;

    @media(max-width: $md) {
        padding-top: 0;
    }

    .mobile-notifications-filters {
        padding-top: 10px;
        position: relative;

        .change-mobile-notifications-filter {
            background-color: transparent;
            width: 100%;
            border: 0;
            color: white;
            font-size: 18px;
            text-align: center;
            position: relative;
            z-index: 3;
            padding-top: 20px;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:focus {
                outline: 0;
            }
        }

        .mobile-notifications-filters-dropdown {
            top: 0;
            position: absolute;
            z-index: 2;
            width: 100%;
            background-color: #1C1C1E;
            padding: 30px;
            padding-top: 70px;
            border-radius: 0 0 30px 30px;
            overflow: hidden;
        }
    }
}
</style>
